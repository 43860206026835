const themes = {
    "modalsearch-light": {
        "scheme": "ModalSearch Light",
        "author": "Max S",
        "base00": "#02182B",
        "base01": "#042B4E",
        "base02": "#053661",
        "base03": "#E8EBF7",
        "base04": "#6b7394",
        "base05": "#3B4054",
        "base06": "#333848",
        "base07": "#202746",
        "base08": "#c94922",
        "base09": "#6F584B",
        "base0A": "#1C448E",
        "base0B": "#15795F",
        "base0C": "#22a2c9",
        "base0D": "#3d8fd1",
        "base0E": "#6679cc",
        "base0F": "#9c637a"
      },
    "rebecca": {
      "scheme": "Rebecca",
      "author": "Victor Borja (http://github.com/vic) based on Rebecca Theme (http://github.com/vic/rebecca-theme)",
      "base00": "#292a44",
      "base01": "#663399",
      "base02": "#383a62",
      "base03": "#666699",
      "base04": "#a0a0c5",
      "base05": "#f1eff8",
      "base06": "#ccccff",
      "base07": "#53495d",
      "base08": "#a0a0c5",
      "base09": "#efe4a1",
      "base0A": "#ae81ff",
      "base0B": "#6dfedf",
      "base0C": "#8eaee0",
      "base0D": "#2de0a7",
      "base0E": "#7aa5ff",
      "base0F": "#ff79c6"
    },
    "github": {
      "scheme": "Github",
      "author": "Defman21",
      "base00": "#ffffff",
      "base01": "#f5f5f5",
      "base02": "#c8c8fa",
      "base03": "#969896",
      "base04": "#e8e8e8",
      "base05": "#333333",
      "base06": "#ffffff",
      "base07": "#ffffff",
      "base08": "#ed6a43",
      "base09": "#0086b3",
      "base0A": "#795da3",
      "base0B": "#183691",
      "base0C": "#183691",
      "base0D": "#795da3",
      "base0E": "#a71d5d",
      "base0F": "#333333"
    },
    "horizon-light": {
      "scheme": "Horizon Light",
      "author": "Michaël Ball (http://github.com/michael-ball/)",
      "base00": "#FDF0ED",
      "base01": "#FADAD1",
      "base02": "#F9CBBE",
      "base03": "#BDB3B1",
      "base04": "#948C8A",
      "base05": "#403C3D",
      "base06": "#302C2D",
      "base07": "#201C1D",
      "base08": "#F7939B",
      "base09": "#F6661E",
      "base0A": "#FBE0D9",
      "base0B": "#94E1B0",
      "base0C": "#DC3318",
      "base0D": "#DA103F",
      "base0E": "#1D8991",
      "base0F": "#E58C92"
    },
    "horizon-terminal-dark": {
      "scheme": "Horizon Dark",
      "author": "Michaël Ball (http://github.com/michael-ball/)",
      "base00": "#1C1E26",
      "base01": "#232530",
      "base02": "#2E303E",
      "base03": "#6F6F70",
      "base04": "#9DA0A2",
      "base05": "#CBCED0",
      "base06": "#DCDFE4",
      "base07": "#E3E6EE",
      "base08": "#E95678",
      "base09": "#FAB795",
      "base0A": "#FAC29A",
      "base0B": "#29D398",
      "base0C": "#59E1E3",
      "base0D": "#26BBD9",
      "base0E": "#EE64AC",
      "base0F": "#F09383"
    },
    "horizon-terminal-light": {
      "scheme": "Horizon Light",
      "author": "Michaël Ball (http://github.com/michael-ball/)",
      "base00": "#FDF0ED",
      "base01": "#FADAD1",
      "base02": "#F9CBBE",
      "base03": "#BDB3B1",
      "base04": "#948C8A",
      "base05": "#403C3D",
      "base06": "#302C2D",
      "base07": "#201C1D",
      "base08": "#E95678",
      "base09": "#F9CEC3",
      "base0A": "#FADAD1",
      "base0B": "#29D398",
      "base0C": "#59E1E3",
      "base0D": "#26BBD9",
      "base0E": "#EE64AC",
      "base0F": "#F9CBBE"
    },
    "horizon-dark": {
      "scheme": "Horizon Dark",
      "author": "Michaël Ball (http://github.com/michael-ball/)",
      "base00": "#1C1E26",
      "base01": "#232530",
      "base02": "#2E303E",
      "base03": "#6F6F70",
      "base04": "#9DA0A2",
      "base05": "#CBCED0",
      "base06": "#DCDFE4",
      "base07": "#E3E6EE",
      "base08": "#E93C58",
      "base09": "#E58D7D",
      "base0A": "#EFB993",
      "base0B": "#EFAF8E",
      "base0C": "#24A8B4",
      "base0D": "#DF5273",
      "base0E": "#B072D1",
      "base0F": "#E4A382"
    },
    "onedark": {
      "scheme": "OneDark",
      "author": "Lalit Magant (http://github.com/tilal6991)",
      "base00": "#282c34",
      "base01": "#353b45",
      "base02": "#3e4451",
      "base03": "#545862",
      "base04": "#565c64",
      "base05": "#abb2bf",
      "base06": "#b6bdca",
      "base07": "#c8ccd4",
      "base08": "#e06c75",
      "base09": "#d19a66",
      "base0A": "#e5c07b",
      "base0B": "#98c379",
      "base0C": "#56b6c2",
      "base0D": "#61afef",
      "base0E": "#c678dd",
      "base0F": "#be5046"
    },
    "solarized-light": {
      "scheme": "Solarized Light",
      "author": "Ethan Schoonover (modified by aramisgithub)",
      "base00": "#fdf6e3",
      "base01": "#eee8d5",
      "base02": "#93a1a1",
      "base03": "#839496",
      "base04": "#657b83",
      "base05": "#586e75",
      "base06": "#073642",
      "base07": "#002b36",
      "base08": "#dc322f",
      "base09": "#cb4b16",
      "base0A": "#b58900",
      "base0B": "#859900",
      "base0C": "#2aa198",
      "base0D": "#268bd2",
      "base0E": "#6c71c4",
      "base0F": "#d33682"
    },
    "solarized-dark": {
      "scheme": "Solarized Dark",
      "author": "Ethan Schoonover (modified by aramisgithub)",
      "base00": "#002b36",
      "base01": "#073642",
      "base02": "#586e75",
      "base03": "#657b83",
      "base04": "#839496",
      "base05": "#93a1a1",
      "base06": "#eee8d5",
      "base07": "#fdf6e3",
      "base08": "#dc322f",
      "base09": "#cb4b16",
      "base0A": "#b58900",
      "base0B": "#859900",
      "base0C": "#2aa198",
      "base0D": "#268bd2",
      "base0E": "#6c71c4",
      "base0F": "#d33682"
    },
    "materia": {
      "scheme": "Materia",
      "author": "Defman21",
      "base00": "#263238",
      "base01": "#2C393F",
      "base02": "#37474F",
      "base03": "#707880",
      "base04": "#C9CCD3",
      "base05": "#CDD3DE",
      "base06": "#D5DBE5",
      "base07": "#FFFFFF",
      "base08": "#EC5F67",
      "base09": "#EA9560",
      "base0A": "#FFCC00",
      "base0B": "#8BD649",
      "base0C": "#80CBC4",
      "base0D": "#89DDFF",
      "base0E": "#82AAFF",
      "base0F": "#EC5F67"
    },
    "porple": {
      "scheme": "Porple",
      "author": "Niek den Breeje (https://github.com/AuditeMarlow)",
      "base00": "#292c36",
      "base01": "#333344",
      "base02": "#474160",
      "base03": "#65568a",
      "base04": "#b8b8b8",
      "base05": "#d8d8d8",
      "base06": "#e8e8e8",
      "base07": "#f8f8f8",
      "base08": "#f84547",
      "base09": "#d28e5d",
      "base0A": "#efa16b",
      "base0B": "#95c76f",
      "base0C": "#64878f",
      "base0D": "#8485ce",
      "base0E": "#b74989",
      "base0F": "#986841"
    },
    "gruvbox-dark-soft": {
      "scheme": "Gruvbox dark, soft",
      "author": "Dawid Kurek (dawikur@gmail.com), morhetz (https://github.com/morhetz/gruvbox)",
      "base00": "#32302f",
      "base01": "#3c3836",
      "base02": "#504945",
      "base03": "#665c54",
      "base04": "#bdae93",
      "base05": "#d5c4a1",
      "base06": "#ebdbb2",
      "base07": "#fbf1c7",
      "base08": "#fb4934",
      "base09": "#fe8019",
      "base0A": "#fabd2f",
      "base0B": "#b8bb26",
      "base0C": "#8ec07c",
      "base0D": "#83a598",
      "base0E": "#d3869b",
      "base0F": "#d65d0e"
    },
    "gruvbox-dark-pale": {
      "scheme": "Gruvbox dark, pale",
      "author": "Dawid Kurek (dawikur@gmail.com), morhetz (https://github.com/morhetz/gruvbox)",
      "base00": "#262626",
      "base01": "#3a3a3a",
      "base02": "#4e4e4e",
      "base03": "#8a8a8a",
      "base04": "#949494",
      "base05": "#dab997",
      "base06": "#d5c4a1",
      "base07": "#ebdbb2",
      "base08": "#d75f5f",
      "base09": "#ff8700",
      "base0A": "#ffaf00",
      "base0B": "#afaf00",
      "base0C": "#85ad85",
      "base0D": "#83adad",
      "base0E": "#d485ad",
      "base0F": "#d65d0e"
    },
    "gruvbox-light-medium": {
      "scheme": "Gruvbox light, medium",
      "author": "Dawid Kurek (dawikur@gmail.com), morhetz (https://github.com/morhetz/gruvbox)",
      "base00": "#fbf1c7",
      "base01": "#ebdbb2",
      "base02": "#d5c4a1",
      "base03": "#bdae93",
      "base04": "#665c54",
      "base05": "#504945",
      "base06": "#3c3836",
      "base07": "#282828",
      "base08": "#9d0006",
      "base09": "#af3a03",
      "base0A": "#b57614",
      "base0B": "#79740e",
      "base0C": "#427b58",
      "base0D": "#076678",
      "base0E": "#8f3f71",
      "base0F": "#d65d0e"
    },
    "gruvbox-light-hard": {
      "scheme": "Gruvbox light, hard",
      "author": "Dawid Kurek (dawikur@gmail.com), morhetz (https://github.com/morhetz/gruvbox)",
      "base00": "#f9f5d7",
      "base01": "#ebdbb2",
      "base02": "#d5c4a1",
      "base03": "#bdae93",
      "base04": "#665c54",
      "base05": "#504945",
      "base06": "#3c3836",
      "base07": "#282828",
      "base08": "#9d0006",
      "base09": "#af3a03",
      "base0A": "#b57614",
      "base0B": "#79740e",
      "base0C": "#427b58",
      "base0D": "#076678",
      "base0E": "#8f3f71",
      "base0F": "#d65d0e"
    },
    "gruvbox-dark-medium": {
      "scheme": "Gruvbox dark, medium",
      "author": "Dawid Kurek (dawikur@gmail.com), morhetz (https://github.com/morhetz/gruvbox)",
      "base00": "#282828",
      "base01": "#3c3836",
      "base02": "#504945",
      "base03": "#665c54",
      "base04": "#bdae93",
      "base05": "#d5c4a1",
      "base06": "#ebdbb2",
      "base07": "#fbf1c7",
      "base08": "#fb4934",
      "base09": "#fe8019",
      "base0A": "#fabd2f",
      "base0B": "#b8bb26",
      "base0C": "#8ec07c",
      "base0D": "#83a598",
      "base0E": "#d3869b",
      "base0F": "#d65d0e"
    },
    "gruvbox-light-soft": {
      "scheme": "Gruvbox light, soft",
      "author": "Dawid Kurek (dawikur@gmail.com), morhetz (https://github.com/morhetz/gruvbox)",
      "base00": "#f2e5bc",
      "base01": "#ebdbb2",
      "base02": "#d5c4a1",
      "base03": "#bdae93",
      "base04": "#665c54",
      "base05": "#504945",
      "base06": "#3c3836",
      "base07": "#282828",
      "base08": "#9d0006",
      "base09": "#af3a03",
      "base0A": "#b57614",
      "base0B": "#79740e",
      "base0C": "#427b58",
      "base0D": "#076678",
      "base0E": "#8f3f71",
      "base0F": "#d65d0e"
    },
    "gruvbox-dark-hard": {
      "scheme": "Gruvbox dark, hard",
      "author": "Dawid Kurek (dawikur@gmail.com), morhetz (https://github.com/morhetz/gruvbox)",
      "base00": "#1d2021",
      "base01": "#3c3836",
      "base02": "#504945",
      "base03": "#665c54",
      "base04": "#bdae93",
      "base05": "#d5c4a1",
      "base06": "#ebdbb2",
      "base07": "#fbf1c7",
      "base08": "#fb4934",
      "base09": "#fe8019",
      "base0A": "#fabd2f",
      "base0B": "#b8bb26",
      "base0C": "#8ec07c",
      "base0D": "#83a598",
      "base0E": "#d3869b",
      "base0F": "#d65d0e"
    },
    "material-darker": {
      "scheme": "Material Darker",
      "author": "Nate Peterson",
      "base00": "#212121",
      "base01": "#303030",
      "base02": "#353535",
      "base03": "#4A4A4A",
      "base04": "#B2CCD6",
      "base05": "#EEFFFF",
      "base06": "#EEFFFF",
      "base07": "#FFFFFF",
      "base08": "#F07178",
      "base09": "#F78C6C",
      "base0A": "#FFCB6B",
      "base0B": "#C3E88D",
      "base0C": "#89DDFF",
      "base0D": "#82AAFF",
      "base0E": "#C792EA",
      "base0F": "#FF5370"
    },
    "material-palenight": {
      "scheme": "Material Palenight",
      "author": "Nate Peterson",
      "base00": "#292D3E",
      "base01": "#444267",
      "base02": "#32374D",
      "base03": "#676E95",
      "base04": "#8796B0",
      "base05": "#959DCB",
      "base06": "#959DCB",
      "base07": "#FFFFFF",
      "base08": "#F07178",
      "base09": "#F78C6C",
      "base0A": "#FFCB6B",
      "base0B": "#C3E88D",
      "base0C": "#89DDFF",
      "base0D": "#82AAFF",
      "base0E": "#C792EA",
      "base0F": "#FF5370"
    },
    "material-lighter": {
      "scheme": "Material Lighter",
      "author": "Nate Peterson",
      "base00": "#FAFAFA",
      "base01": "#E7EAEC",
      "base02": "#CCEAE7",
      "base03": "#CCD7DA",
      "base04": "#8796B0",
      "base05": "#80CBC4",
      "base06": "#80CBC4",
      "base07": "#FFFFFF",
      "base08": "#FF5370",
      "base09": "#F76D47",
      "base0A": "#FFB62C",
      "base0B": "#91B859",
      "base0C": "#39ADB5",
      "base0D": "#6182B8",
      "base0E": "#7C4DFF",
      "base0F": "#E53935"
    },
    "material": {
      "scheme": "Material",
      "author": "Nate Peterson",
      "base00": "#263238",
      "base01": "#2E3C43",
      "base02": "#314549",
      "base03": "#546E7A",
      "base04": "#B2CCD6",
      "base05": "#EEFFFF",
      "base06": "#EEFFFF",
      "base07": "#FFFFFF",
      "base08": "#F07178",
      "base09": "#F78C6C",
      "base0A": "#FFCB6B",
      "base0B": "#C3E88D",
      "base0C": "#89DDFF",
      "base0D": "#82AAFF",
      "base0E": "#C792EA",
      "base0F": "#FF5370"
    },
    "mexico-light": {
      "scheme": "Mexico Light",
      "author": "Sheldon Johnson",
      "base00": "#f8f8f8",
      "base01": "#e8e8e8",
      "base02": "#d8d8d8",
      "base03": "#b8b8b8",
      "base04": "#585858",
      "base05": "#383838",
      "base06": "#282828",
      "base07": "#181818",
      "base08": "#ab4642",
      "base09": "#dc9656",
      "base0A": "#f79a0e",
      "base0B": "#538947",
      "base0C": "#4b8093",
      "base0D": "#7cafc2",
      "base0E": "#96609e",
      "base0F": "#a16946"
    },
    "tomorrow-night": {
      "scheme": "Tomorrow Night",
      "author": "Chris Kempson (http://chriskempson.com)",
      "base00": "#1d1f21",
      "base01": "#282a2e",
      "base02": "#373b41",
      "base03": "#969896",
      "base04": "#b4b7b4",
      "base05": "#c5c8c6",
      "base06": "#e0e0e0",
      "base07": "#ffffff",
      "base08": "#cc6666",
      "base09": "#de935f",
      "base0A": "#f0c674",
      "base0B": "#b5bd68",
      "base0C": "#8abeb7",
      "base0D": "#81a2be",
      "base0E": "#b294bb",
      "base0F": "#a3685a"
    },
    "tomorrow": {
      "scheme": "Tomorrow",
      "author": "Chris Kempson (http://chriskempson.com)",
      "base00": "#ffffff",
      "base01": "#e0e0e0",
      "base02": "#d6d6d6",
      "base03": "#8e908c",
      "base04": "#969896",
      "base05": "#4d4d4c",
      "base06": "#282a2e",
      "base07": "#1d1f21",
      "base08": "#c82829",
      "base09": "#f5871f",
      "base0A": "#eab700",
      "base0B": "#718c00",
      "base0C": "#3e999f",
      "base0D": "#4271ae",
      "base0E": "#8959a8",
      "base0F": "#a3685a"
    },
    "tomorrow-night-eighties": {
      "scheme": "Tomorrow Night",
      "author": "Chris Kempson (http://chriskempson.com)",
      "base00": "#2d2d2d",
      "base01": "#393939",
      "base02": "#515151",
      "base03": "#999999",
      "base04": "#b4b7b4",
      "base05": "#cccccc",
      "base06": "#e0e0e0",
      "base07": "#ffffff",
      "base08": "#f2777a",
      "base09": "#f99157",
      "base0A": "#ffcc66",
      "base0B": "#99cc99",
      "base0C": "#66cccc",
      "base0D": "#6699cc",
      "base0E": "#cc99cc",
      "base0F": "#a3685a"
    },
    "mellow-purple": {
      "scheme": "Mellow Purple",
      "author": "gidsi",
      "base00": "#1e0528",
      "base01": "#1A092D",
      "base02": "#331354",
      "base03": "#320f55",
      "base04": "#873582",
      "base05": "#ffeeff",
      "base06": "#ffeeff",
      "base07": "#f8c0ff",
      "base08": "#00d9e9",
      "base09": "#aa00a3",
      "base0A": "#955ae7",
      "base0B": "#05cb0d",
      "base0C": "#b900b1",
      "base0D": "#550068",
      "base0E": "#8991bb",
      "base0F": "#4d6fff"
    },
    "classic-light": {
      "scheme": "Classic Light",
      "author": "Jason Heeris (http://heeris.id.au)",
      "base00": "#F5F5F5",
      "base01": "#E0E0E0",
      "base02": "#D0D0D0",
      "base03": "#B0B0B0",
      "base04": "#505050",
      "base05": "#303030",
      "base06": "#202020",
      "base07": "#151515",
      "base08": "#AC4142",
      "base09": "#D28445",
      "base0A": "#F4BF75",
      "base0B": "#90A959",
      "base0C": "#75B5AA",
      "base0D": "#6A9FB5",
      "base0E": "#AA759F",
      "base0F": "#8F5536"
    },
    "classic-dark": {
      "scheme": "Classic Dark",
      "author": "Jason Heeris (http://heeris.id.au)",
      "base00": "#151515",
      "base01": "#202020",
      "base02": "#303030",
      "base03": "#505050",
      "base04": "#B0B0B0",
      "base05": "#D0D0D0",
      "base06": "#E0E0E0",
      "base07": "#F5F5F5",
      "base08": "#AC4142",
      "base09": "#D28445",
      "base0A": "#F4BF75",
      "base0B": "#90A959",
      "base0C": "#75B5AA",
      "base0D": "#6A9FB5",
      "base0E": "#AA759F",
      "base0F": "#8F5536"
    },
    "ocean": {
      "scheme": "Ocean",
      "author": "Chris Kempson (http://chriskempson.com)",
      "base00": "#2b303b",
      "base01": "#343d46",
      "base02": "#4f5b66",
      "base03": "#65737e",
      "base04": "#a7adba",
      "base05": "#c0c5ce",
      "base06": "#dfe1e8",
      "base07": "#eff1f5",
      "base08": "#bf616a",
      "base09": "#d08770",
      "base0A": "#ebcb8b",
      "base0B": "#a3be8c",
      "base0C": "#96b5b4",
      "base0D": "#8fa1b3",
      "base0E": "#b48ead",
      "base0F": "#ab7967"
    },
    "default-light": {
      "scheme": "Default Light",
      "author": "Chris Kempson (http://chriskempson.com)",
      "base00": "#f8f8f8",
      "base01": "#e8e8e8",
      "base02": "#d8d8d8",
      "base03": "#b8b8b8",
      "base04": "#585858",
      "base05": "#383838",
      "base06": "#282828",
      "base07": "#181818",
      "base08": "#ab4642",
      "base09": "#dc9656",
      "base0A": "#f7ca88",
      "base0B": "#a1b56c",
      "base0C": "#86c1b9",
      "base0D": "#7cafc2",
      "base0E": "#ba8baf",
      "base0F": "#a16946"
    },
    "mocha": {
      "scheme": "Mocha",
      "author": "Chris Kempson (http://chriskempson.com)",
      "base00": "#3B3228",
      "base01": "#534636",
      "base02": "#645240",
      "base03": "#7e705a",
      "base04": "#b8afad",
      "base05": "#d0c8c6",
      "base06": "#e9e1dd",
      "base07": "#f5eeeb",
      "base08": "#cb6077",
      "base09": "#d28b71",
      "base0A": "#f4bc87",
      "base0B": "#beb55b",
      "base0C": "#7bbda4",
      "base0D": "#8ab3b5",
      "base0E": "#a89bb9",
      "base0F": "#bb9584"
    },
    "default-dark": {
      "scheme": "Default Dark",
      "author": "Chris Kempson (http://chriskempson.com)",
      "base00": "#181818",
      "base01": "#282828",
      "base02": "#383838",
      "base03": "#585858",
      "base04": "#b8b8b8",
      "base05": "#d8d8d8",
      "base06": "#e8e8e8",
      "base07": "#f8f8f8",
      "base08": "#ab4642",
      "base09": "#dc9656",
      "base0A": "#f7ca88",
      "base0B": "#a1b56c",
      "base0C": "#86c1b9",
      "base0D": "#7cafc2",
      "base0E": "#ba8baf",
      "base0F": "#a16946"
    },
    "cupcake": {
      "scheme": "Cupcake",
      "author": "Chris Kempson (http://chriskempson.com)",
      "base00": "#fbf1f2",
      "base01": "#f2f1f4",
      "base02": "#d8d5dd",
      "base03": "#bfb9c6",
      "base04": "#a59daf",
      "base05": "#8b8198",
      "base06": "#72677E",
      "base07": "#585062",
      "base08": "#D57E85",
      "base09": "#EBB790",
      "base0A": "#DCB16C",
      "base0B": "#A3B367",
      "base0C": "#69A9A7",
      "base0D": "#7297B9",
      "base0E": "#BB99B4",
      "base0F": "#BAA58C"
    },
    "eighties": {
      "scheme": "Eighties",
      "author": "Chris Kempson (http://chriskempson.com)",
      "base00": "#2d2d2d",
      "base01": "#393939",
      "base02": "#515151",
      "base03": "#747369",
      "base04": "#a09f93",
      "base05": "#d3d0c8",
      "base06": "#e8e6df",
      "base07": "#f2f0ec",
      "base08": "#f2777a",
      "base09": "#f99157",
      "base0A": "#ffcc66",
      "base0B": "#99cc99",
      "base0C": "#66cccc",
      "base0D": "#6699cc",
      "base0E": "#cc99cc",
      "base0F": "#d27b53"
    },
    "papercolor-dark": {
      "scheme": "PaperColor Dark",
      "author": "Jon Leopard (http://github.com/jonleopard) based on PaperColor Theme (https://github.com/NLKNguyen/papercolor-theme)",
      "base00": "#1c1c1c",
      "base01": "#af005f",
      "base02": "#5faf00",
      "base03": "#d7af5f",
      "base04": "#5fafd7",
      "base05": "#808080",
      "base06": "#d7875f",
      "base07": "#d0d0d0",
      "base08": "#585858",
      "base09": "#5faf5f",
      "base0A": "#afd700",
      "base0B": "#af87d7",
      "base0C": "#ffaf00",
      "base0D": "#ff5faf",
      "base0E": "#00afaf",
      "base0F": "#5f8787"
    },
    "papercolor-light": {
      "scheme": "PaperColor Light",
      "author": "Jon Leopard (http://github.com/jonleopard) based on PaperColor Theme (https://github.com/NLKNguyen/papercolor-theme)",
      "base00": "#eeeeee",
      "base01": "#af0000",
      "base02": "#008700",
      "base03": "#5f8700",
      "base04": "#0087af",
      "base05": "#444444",
      "base06": "#005f87",
      "base07": "#878787",
      "base08": "#bcbcbc",
      "base09": "#d70000",
      "base0A": "#d70087",
      "base0B": "#8700af",
      "base0C": "#d75f00",
      "base0D": "#d75f00",
      "base0E": "#005faf",
      "base0F": "#005f87"
    },
    "black-metal-khold": {
      "scheme": "Black Metal (Khold)",
      "author": "metalelf0 (https://github.com/metalelf0)",
      "base00": "#000000",
      "base01": "#121212",
      "base02": "#222222",
      "base03": "#333333",
      "base04": "#999999",
      "base05": "#c1c1c1",
      "base06": "#999999",
      "base07": "#c1c1c1",
      "base08": "#5f8787",
      "base09": "#aaaaaa",
      "base0A": "#974b46",
      "base0B": "#eceee3",
      "base0C": "#aaaaaa",
      "base0D": "#888888",
      "base0E": "#999999",
      "base0F": "#444444"
    },
    "black-metal-bathory": {
      "scheme": "Black Metal (Bathory)",
      "author": "metalelf0 (https://github.com/metalelf0)",
      "base00": "#000000",
      "base01": "#121212",
      "base02": "#222222",
      "base03": "#333333",
      "base04": "#999999",
      "base05": "#c1c1c1",
      "base06": "#999999",
      "base07": "#c1c1c1",
      "base08": "#5f8787",
      "base09": "#aaaaaa",
      "base0A": "#e78a53",
      "base0B": "#fbcb97",
      "base0C": "#aaaaaa",
      "base0D": "#888888",
      "base0E": "#999999",
      "base0F": "#444444"
    },
    "black-metal-venom": {
      "scheme": "Black Metal (Venom)",
      "author": "metalelf0 (https://github.com/metalelf0)",
      "base00": "#000000",
      "base01": "#121212",
      "base02": "#222222",
      "base03": "#333333",
      "base04": "#999999",
      "base05": "#c1c1c1",
      "base06": "#999999",
      "base07": "#c1c1c1",
      "base08": "#5f8787",
      "base09": "#aaaaaa",
      "base0A": "#79241f",
      "base0B": "#f8f7f2",
      "base0C": "#aaaaaa",
      "base0D": "#888888",
      "base0E": "#999999",
      "base0F": "#444444"
    },
    "black-metal-gorgoroth": {
      "scheme": "Black Metal (Gorgoroth)",
      "author": "metalelf0 (https://github.com/metalelf0)",
      "base00": "#000000",
      "base01": "#121212",
      "base02": "#222222",
      "base03": "#333333",
      "base04": "#999999",
      "base05": "#c1c1c1",
      "base06": "#999999",
      "base07": "#c1c1c1",
      "base08": "#5f8787",
      "base09": "#aaaaaa",
      "base0A": "#8c7f70",
      "base0B": "#9b8d7f",
      "base0C": "#aaaaaa",
      "base0D": "#888888",
      "base0E": "#999999",
      "base0F": "#444444"
    },
    "black-metal-marduk": {
      "scheme": "Black Metal (Marduk)",
      "author": "metalelf0 (https://github.com/metalelf0)",
      "base00": "#000000",
      "base01": "#121212",
      "base02": "#222222",
      "base03": "#333333",
      "base04": "#999999",
      "base05": "#c1c1c1",
      "base06": "#999999",
      "base07": "#c1c1c1",
      "base08": "#5f8787",
      "base09": "#aaaaaa",
      "base0A": "#626b67",
      "base0B": "#a5aaa7",
      "base0C": "#aaaaaa",
      "base0D": "#888888",
      "base0E": "#999999",
      "base0F": "#444444"
    },
    "black-metal": {
      "scheme": "Black Metal",
      "author": "metalelf0 (https://github.com/metalelf0)",
      "base00": "#000000",
      "base01": "#121212",
      "base02": "#222222",
      "base03": "#333333",
      "base04": "#999999",
      "base05": "#c1c1c1",
      "base06": "#999999",
      "base07": "#c1c1c1",
      "base08": "#5f8787",
      "base09": "#aaaaaa",
      "base0A": "#a06666",
      "base0B": "#dd9999",
      "base0C": "#aaaaaa",
      "base0D": "#888888",
      "base0E": "#999999",
      "base0F": "#444444"
    },
    "black-metal-nile": {
      "scheme": "Black Metal (Nile)",
      "author": "metalelf0 (https://github.com/metalelf0)",
      "base00": "#000000",
      "base01": "#121212",
      "base02": "#222222",
      "base03": "#333333",
      "base04": "#999999",
      "base05": "#c1c1c1",
      "base06": "#999999",
      "base07": "#c1c1c1",
      "base08": "#5f8787",
      "base09": "#aaaaaa",
      "base0A": "#777755",
      "base0B": "#aa9988",
      "base0C": "#aaaaaa",
      "base0D": "#888888",
      "base0E": "#999999",
      "base0F": "#444444"
    },
    "black-metal-immortal": {
      "scheme": "Black Metal (Immortal)",
      "author": "metalelf0 (https://github.com/metalelf0)",
      "base00": "#000000",
      "base01": "#121212",
      "base02": "#222222",
      "base03": "#333333",
      "base04": "#999999",
      "base05": "#c1c1c1",
      "base06": "#999999",
      "base07": "#c1c1c1",
      "base08": "#5f8787",
      "base09": "#aaaaaa",
      "base0A": "#556677",
      "base0B": "#7799bb",
      "base0C": "#aaaaaa",
      "base0D": "#888888",
      "base0E": "#999999",
      "base0F": "#444444"
    },
    "black-metal-burzum": {
      "scheme": "Black Metal (Burzum)",
      "author": "metalelf0 (https://github.com/metalelf0)",
      "base00": "#000000",
      "base01": "#121212",
      "base02": "#222222",
      "base03": "#333333",
      "base04": "#999999",
      "base05": "#c1c1c1",
      "base06": "#999999",
      "base07": "#c1c1c1",
      "base08": "#5f8787",
      "base09": "#aaaaaa",
      "base0A": "#99bbaa",
      "base0B": "#ddeecc",
      "base0C": "#aaaaaa",
      "base0D": "#888888",
      "base0E": "#999999",
      "base0F": "#444444"
    },
    "black-metal-mayhem": {
      "scheme": "Black Metal (Mayhem)",
      "author": "metalelf0 (https://github.com/metalelf0)",
      "base00": "#000000",
      "base01": "#121212",
      "base02": "#222222",
      "base03": "#333333",
      "base04": "#999999",
      "base05": "#c1c1c1",
      "base06": "#999999",
      "base07": "#c1c1c1",
      "base08": "#5f8787",
      "base09": "#aaaaaa",
      "base0A": "#eecc6c",
      "base0B": "#f3ecd4",
      "base0C": "#aaaaaa",
      "base0D": "#888888",
      "base0E": "#999999",
      "base0F": "#444444"
    },
    "black-metal-dark-funeral": {
      "scheme": "Black Metal (Dark Funeral)",
      "author": "metalelf0 (https://github.com/metalelf0)",
      "base00": "#000000",
      "base01": "#121212",
      "base02": "#222222",
      "base03": "#333333",
      "base04": "#999999",
      "base05": "#c1c1c1",
      "base06": "#999999",
      "base07": "#c1c1c1",
      "base08": "#5f8787",
      "base09": "#aaaaaa",
      "base0A": "#5f81a5",
      "base0B": "#d0dfee",
      "base0C": "#aaaaaa",
      "base0D": "#888888",
      "base0E": "#999999",
      "base0F": "#444444"
    },
    "hardcore": {
      "scheme": "Hardcore",
      "author": "Chris Caller",
      "base00": "#212121",
      "base01": "#303030",
      "base02": "#353535",
      "base03": "#4A4A4A",
      "base04": "#707070",
      "base05": "#cdcdcd",
      "base06": "#e5e5e5",
      "base07": "#ffffff",
      "base08": "#f92672",
      "base09": "#fd971f",
      "base0A": "#e6db74",
      "base0B": "#a6e22e",
      "base0C": "#708387",
      "base0D": "#66d9ef",
      "base0E": "#9e6ffe",
      "base0F": "#e8b882"
    },
    "circus": {
      "scheme": "Circus",
      "author": "Stephan Boyer (https://github.com/stepchowfun) and Esther Wang (https://github.com/ewang12)",
      "base00": "#191919",
      "base01": "#202020",
      "base02": "#303030",
      "base03": "#5f5a60",
      "base04": "#505050",
      "base05": "#a7a7a7",
      "base06": "#808080",
      "base07": "#ffffff",
      "base08": "#dc657d",
      "base09": "#4bb1a7",
      "base0A": "#c3ba63",
      "base0B": "#84b97c",
      "base0C": "#4bb1a7",
      "base0D": "#639ee4",
      "base0E": "#b888e2",
      "base0F": "#b888e2"
    },
    "decaf": {
      "scheme": "Decaf",
      "author": "Alex Mirrington (https://github.com/alexmirrington)",
      "base00": "#2d2d2d",
      "base01": "#393939",
      "base02": "#515151",
      "base03": "#777777",
      "base04": "#b4b7b4",
      "base05": "#cccccc",
      "base06": "#e0e0e0",
      "base07": "#ffffff",
      "base08": "#ff7f7b",
      "base09": "#ffbf70",
      "base0A": "#ffd67c",
      "base0B": "#beda78",
      "base0C": "#bed6ff",
      "base0D": "#90bee1",
      "base0E": "#efb3f7",
      "base0F": "#ff93b3"
    },
    "espresso": {
      "scheme": "Espresso",
      "author": "Alex Mirrington (https://github.com/alexmirrington)",
      "base00": "#2d2d2d",
      "base01": "#393939",
      "base02": "#515151",
      "base03": "#777777",
      "base04": "#b4b7b4",
      "base05": "#cccccc",
      "base06": "#e0e0e0",
      "base07": "#ffffff",
      "base08": "#d25252",
      "base09": "#f9a959",
      "base0A": "#ffc66d",
      "base0B": "#a5c261",
      "base0C": "#bed6ff",
      "base0D": "#6c99bb",
      "base0E": "#d197d9",
      "base0F": "#f97394"
    },
    "outrun-dark": {
      "scheme": "Outrun Dark",
      "author": "Hugo Delahousse (http://github.com/hugodelahousse/)",
      "base00": "#00002A",
      "base01": "#20204A",
      "base02": "#30305A",
      "base03": "#50507A",
      "base04": "#B0B0DA",
      "base05": "#D0D0FA",
      "base06": "#E0E0FF",
      "base07": "#F5F5FF",
      "base08": "#FF4242",
      "base09": "#FC8D28",
      "base0A": "#F3E877",
      "base0B": "#59F176",
      "base0C": "#0EF0F0",
      "base0D": "#66B0FF",
      "base0E": "#F10596",
      "base0F": "#F003EF"
    },
    "cupertino": {
      "scheme": "Cupertino",
      "author": "Defman21",
      "base00": "#ffffff",
      "base01": "#c0c0c0",
      "base02": "#c0c0c0",
      "base03": "#808080",
      "base04": "#808080",
      "base05": "#404040",
      "base06": "#404040",
      "base07": "#5e5e5e",
      "base08": "#c41a15",
      "base09": "#eb8500",
      "base0A": "#826b28",
      "base0B": "#007400",
      "base0C": "#318495",
      "base0D": "#0000ff",
      "base0E": "#a90d91",
      "base0F": "#826b28"
    },
    "solarflare": {
      "scheme": "Solar Flare",
      "author": "Chuck Harmston (https://chuck.harmston.ch)",
      "base00": "#18262F",
      "base01": "#222E38",
      "base02": "#586875",
      "base03": "#667581",
      "base04": "#85939E",
      "base05": "#A6AFB8",
      "base06": "#E8E9ED",
      "base07": "#F5F7FA",
      "base08": "#EF5253",
      "base09": "#E66B2B",
      "base0A": "#E4B51C",
      "base0B": "#7CC844",
      "base0C": "#52CBB0",
      "base0D": "#33B5E1",
      "base0E": "#A363D5",
      "base0F": "#D73C9A"
    },
    "one-light": {
      "scheme": "One Light",
      "author": "Daniel Pfeifer (http://github.com/purpleKarrot)",
      "base00": "#fafafa",
      "base01": "#f0f0f1",
      "base02": "#e5e5e6",
      "base03": "#a0a1a7",
      "base04": "#696c77",
      "base05": "#383a42",
      "base06": "#202227",
      "base07": "#090a0b",
      "base08": "#ca1243",
      "base09": "#d75f00",
      "base0A": "#c18401",
      "base0B": "#50a14f",
      "base0C": "#0184bc",
      "base0D": "#4078f2",
      "base0E": "#a626a4",
      "base0F": "#986801"
    },
    "pasque": {
      "scheme": "Pasque",
      "author": "Gabriel Fontes (https://github.com/Misterio77)",
      "base00": "#271C3A",
      "base01": "#100323",
      "base02": "#3E2D5C",
      "base03": "#5D5766",
      "base04": "#BEBCBF",
      "base05": "#DEDCDF",
      "base06": "#EDEAEF",
      "base07": "#BBAADD",
      "base08": "#A92258",
      "base09": "#918889",
      "base0A": "#804ead",
      "base0B": "#C6914B",
      "base0C": "#7263AA",
      "base0D": "#8E7DC6",
      "base0E": "#953B9D",
      "base0F": "#59325C"
    },
    "summerfruit-dark": {
      "scheme": "Summerfruit Dark",
      "author": "Christopher Corley (http://christop.club/)",
      "base00": "#151515",
      "base01": "#202020",
      "base02": "#303030",
      "base03": "#505050",
      "base04": "#B0B0B0",
      "base05": "#D0D0D0",
      "base06": "#E0E0E0",
      "base07": "#FFFFFF",
      "base08": "#FF0086",
      "base09": "#FD8900",
      "base0A": "#ABA800",
      "base0B": "#00C918",
      "base0C": "#1FAAAA",
      "base0D": "#3777E6",
      "base0E": "#AD00A1",
      "base0F": "#CC6633"
    },
    "summerfruit-light": {
      "scheme": "Summerfruit Light",
      "author": "Christopher Corley (http://christop.club/)",
      "base00": "#FFFFFF",
      "base01": "#E0E0E0",
      "base02": "#D0D0D0",
      "base03": "#B0B0B0",
      "base04": "#000000",
      "base05": "#101010",
      "base06": "#151515",
      "base07": "#202020",
      "base08": "#FF0086",
      "base09": "#FD8900",
      "base0A": "#ABA800",
      "base0B": "#00C918",
      "base0C": "#1FAAAA",
      "base0D": "#3777E6",
      "base0E": "#AD00A1",
      "base0F": "#CC6633"
    },
    "summercamp": {
      "scheme": "summercamp",
      "author": "zoe firi (zoefiri.github.io)",
      "base00": "#1c1810",
      "base01": "#2a261c",
      "base02": "#3a3527",
      "base03": "#504b38",
      "base04": "#5f5b45",
      "base05": "#736e55",
      "base06": "#bab696",
      "base07": "#f8f5de",
      "base08": "#e35142",
      "base09": "#fba11b",
      "base0A": "#f2ff27",
      "base0B": "#5ceb5a",
      "base0C": "#5aebbc",
      "base0D": "#489bf0",
      "base0E": "#FF8080",
      "base0F": "#F69BE7"
    },
    "equilibrium-gray-dark": {
      "scheme": "Equilibrium Gray Dark",
      "author": "Carlo Abelli",
      "base00": "#111111",
      "base01": "#1b1b1b",
      "base02": "#262626",
      "base03": "#777777",
      "base04": "#919191",
      "base05": "#ababab",
      "base06": "#c6c6c6",
      "base07": "#e2e2e2",
      "base08": "#f04339",
      "base09": "#df5923",
      "base0A": "#bb8801",
      "base0B": "#7f8b00",
      "base0C": "#00948b",
      "base0D": "#008dd1",
      "base0E": "#6a7fd2",
      "base0F": "#e3488e"
    },
    "equilibrium-dark": {
      "scheme": "Equilibrium Dark",
      "author": "Carlo Abelli",
      "base00": "#0c1118",
      "base01": "#181c22",
      "base02": "#22262d",
      "base03": "#7b776e",
      "base04": "#949088",
      "base05": "#afaba2",
      "base06": "#cac6bd",
      "base07": "#e7e2d9",
      "base08": "#f04339",
      "base09": "#df5923",
      "base0A": "#bb8801",
      "base0B": "#7f8b00",
      "base0C": "#00948b",
      "base0D": "#008dd1",
      "base0E": "#6a7fd2",
      "base0F": "#e3488e"
    },
    "equilibrium-gray-light": {
      "scheme": "Equilibrium Gray Light",
      "author": "Carlo Abelli",
      "base00": "#f1f1f1",
      "base01": "#e2e2e2",
      "base02": "#d4d4d4",
      "base03": "#777777",
      "base04": "#5e5e5e",
      "base05": "#474747",
      "base06": "#303030",
      "base07": "#1b1b1b",
      "base08": "#d02023",
      "base09": "#bf3e05",
      "base0A": "#9d6f00",
      "base0B": "#637200",
      "base0C": "#007a72",
      "base0D": "#0073b5",
      "base0E": "#4e66b6",
      "base0F": "#c42775"
    },
    "equilibrium-light": {
      "scheme": "Equilibrium Light",
      "author": "Carlo Abelli",
      "base00": "#f5f0e7",
      "base01": "#e7e2d9",
      "base02": "#d8d4cb",
      "base03": "#73777f",
      "base04": "#5a5f66",
      "base05": "#43474e",
      "base06": "#2c3138",
      "base07": "#181c22",
      "base08": "#d02023",
      "base09": "#bf3e05",
      "base0A": "#9d6f00",
      "base0B": "#637200",
      "base0C": "#007a72",
      "base0D": "#0073b5",
      "base0E": "#4e66b6",
      "base0F": "#c42775"
    },
    "woodland": {
      "scheme": "Woodland",
      "author": "Jay Cornwall (https://jcornwall.com)",
      "base00": "#231e18",
      "base01": "#302b25",
      "base02": "#48413a",
      "base03": "#9d8b70",
      "base04": "#b4a490",
      "base05": "#cabcb1",
      "base06": "#d7c8bc",
      "base07": "#e4d4c8",
      "base08": "#d35c5c",
      "base09": "#ca7f32",
      "base0A": "#e0ac16",
      "base0B": "#b7ba53",
      "base0C": "#6eb958",
      "base0D": "#88a4d3",
      "base0E": "#bb90e2",
      "base0F": "#b49368"
    },
    "twilight": {
      "scheme": "Twilight",
      "author": "David Hart (https://github.com/hartbit)",
      "base00": "#1e1e1e",
      "base01": "#323537",
      "base02": "#464b50",
      "base03": "#5f5a60",
      "base04": "#838184",
      "base05": "#a7a7a7",
      "base06": "#c3c3c3",
      "base07": "#ffffff",
      "base08": "#cf6a4c",
      "base09": "#cda869",
      "base0A": "#f9ee98",
      "base0B": "#8f9d6a",
      "base0C": "#afc4db",
      "base0D": "#7587a6",
      "base0E": "#9b859d",
      "base0F": "#9b703f"
    },
    "atlas": {
      "scheme": "Atlas",
      "author": "Alex Lende (https://ajlende.com)",
      "base00": "#002635",
      "base01": "#00384d",
      "base02": "#517F8D",
      "base03": "#6C8B91",
      "base04": "#869696",
      "base05": "#a1a19a",
      "base06": "#e6e6dc",
      "base07": "#fafaf8",
      "base08": "#ff5a67",
      "base09": "#f08e48",
      "base0A": "#ffcc1b",
      "base0B": "#7fc06e",
      "base0D": "#5dd7b9",
      "base0C": "#14747e",
      "base0E": "#9a70a4",
      "base0F": "#c43060"
    },
    "gigavolt": {
      "scheme": "Gigavolt",
      "author": "Aidan Swope (http://github.com/Whillikers)",
      "base00": "#202126",
      "base01": "#2d303d",
      "base02": "#5a576e",
      "base03": "#a1d2e6",
      "base04": "#cad3ff",
      "base05": "#e9e7e1",
      "base06": "#eff0f9",
      "base07": "#f2fbff",
      "base08": "#ff661a",
      "base09": "#19f988",
      "base0A": "#ffdc2d",
      "base0B": "#f2e6a9",
      "base0C": "#fb6acb",
      "base0D": "#40bfff",
      "base0E": "#ae94f9",
      "base0F": "#6187ff"
    },
    "brogrammer": {
      "scheme": "Brogrammer",
      "author": "Vik Ramanujam (http://github.com/piggyslasher)",
      "base00": "#1f1f1f",
      "base01": "#f81118",
      "base02": "#2dc55e",
      "base03": "#ecba0f",
      "base04": "#2a84d2",
      "base05": "#4e5ab7",
      "base06": "#1081d6",
      "base07": "#d6dbe5",
      "base08": "#d6dbe5",
      "base09": "#de352e",
      "base0A": "#1dd361",
      "base0B": "#f3bd09",
      "base0C": "#1081d6",
      "base0D": "#5350b9",
      "base0E": "#0f7ddb",
      "base0F": "#ffffff"
    },
    "synth-midnight-dark": {
      "scheme": "Synth Midnight Terminal Dark",
      "author": "Michaël Ball (http://github.com/michael-ball/)",
      "base00": "#050608",
      "base01": "#1a1b1c",
      "base02": "#28292a",
      "base03": "#474849",
      "base04": "#a3a5a6",
      "base05": "#c1c3c4",
      "base06": "#cfd1d2",
      "base07": "#dddfe0",
      "base08": "#b53b50",
      "base09": "#ea770d",
      "base0A": "#c9d364",
      "base0B": "#06ea61",
      "base0C": "#42fff9",
      "base0D": "#03aeff",
      "base0E": "#ea5ce2",
      "base0F": "#cd6320"
    },
    "synth-midnight-light": {
      "scheme": "Synth Midnight Terminal Light",
      "author": "Michaël Ball (http://github.com/michael-ball/)",
      "base00": "#dddfe0",
      "base01": "#cfd1d2",
      "base02": "#c1c3c4",
      "base03": "#a3a5a6",
      "base04": "#474849",
      "base05": "#28292a",
      "base06": "#1a1b1c",
      "base07": "#050608",
      "base08": "#b53b50",
      "base09": "#ea770d",
      "base0A": "#c9d364",
      "base0B": "#06ea61",
      "base0C": "#42fff9",
      "base0D": "#03aeff",
      "base0E": "#ea5ce2",
      "base0F": "#cd6320"
    },
    "zenburn": {
      "scheme": "Zenburn",
      "author": "elnawe",
      "base00": "#383838",
      "base01": "#404040",
      "base02": "#606060",
      "base03": "#6f6f6f",
      "base04": "#808080",
      "base05": "#dcdccc",
      "base06": "#c0c0c0",
      "base07": "#ffffff",
      "base08": "#dca3a3",
      "base09": "#dfaf8f",
      "base0A": "#e0cf9f",
      "base0B": "#5f7f5f",
      "base0C": "#93e0e3",
      "base0D": "#7cb8bb",
      "base0E": "#dc8cc3",
      "base0F": "#000000"
    },
    "unikitty-light": {
      "scheme": "Unikitty Light",
      "author": "Josh W Lewis (@joshwlewis)",
      "base00": "#ffffff",
      "base01": "#e1e1e2",
      "base02": "#c4c3c5",
      "base03": "#a7a5a8",
      "base04": "#89878b",
      "base05": "#6c696e",
      "base06": "#4f4b51",
      "base07": "#322d34",
      "base08": "#d8137f",
      "base09": "#d65407",
      "base0A": "#dc8a0e",
      "base0B": "#17ad98",
      "base0C": "#149bda",
      "base0D": "#775dff",
      "base0E": "#aa17e6",
      "base0F": "#e013d0"
    },
    "unikitty-dark": {
      "scheme": "Unikitty Dark",
      "author": "Josh W Lewis (@joshwlewis)",
      "base00": "#2e2a31",
      "base01": "#4a464d",
      "base02": "#666369",
      "base03": "#838085",
      "base04": "#9f9da2",
      "base05": "#bcbabe",
      "base06": "#d8d7da",
      "base07": "#f5f4f7",
      "base08": "#d8137f",
      "base09": "#d65407",
      "base0A": "#dc8a0e",
      "base0B": "#17ad98",
      "base0C": "#149bda",
      "base0D": "#796af5",
      "base0E": "#bb60ea",
      "base0F": "#c720ca"
    },
    "nebula": {
      "scheme": "Nebula",
      "author": "Gabriel Fontes (https://github.com/Misterio77)",
      "base00": "#22273b",
      "base01": "#414f60",
      "base02": "#5a8380",
      "base03": "#6e6f72",
      "base04": "#87888b",
      "base05": "#a4a6a9",
      "base06": "#c7c9cd",
      "base07": "#8dbdaa",
      "base08": "#777abc",
      "base09": "#94929e",
      "base0A": "#4f9062",
      "base0B": "#6562a8",
      "base0C": "#226f68",
      "base0D": "#4d6bb6",
      "base0E": "#716cae",
      "base0F": "#8c70a7"
    },
    "helios": {
      "scheme": "Helios",
      "author": "Alex Meyer (https://github.com/reyemxela)",
      "base00": "#1d2021",
      "base01": "#383c3e",
      "base02": "#53585b",
      "base03": "#6f7579",
      "base04": "#cdcdcd",
      "base05": "#d5d5d5",
      "base06": "#dddddd",
      "base07": "#e5e5e5",
      "base08": "#d72638",
      "base09": "#eb8413",
      "base0A": "#f19d1a",
      "base0B": "#88b92d",
      "base0C": "#1ba595",
      "base0D": "#1e8bac",
      "base0E": "#be4264",
      "base0F": "#c85e0d"
    },
    "humanoid-dark": {
      "scheme": "Humanoid dark",
      "author": "Thomas (tasmo) Friese",
      "base00": "#232629",
      "base01": "#333b3d",
      "base02": "#484e54",
      "base03": "#60615d",
      "base04": "#c0c0bd",
      "base05": "#f8f8f2",
      "base06": "#fcfcf6",
      "base07": "#fcfcfc",
      "base08": "#f11235",
      "base09": "#ff9505",
      "base0A": "#ffb627",
      "base0B": "#02d849",
      "base0C": "#0dd9d6",
      "base0D": "#00a6fb",
      "base0E": "#f15ee3",
      "base0F": "#b27701"
    },
    "humanoid-light": {
      "scheme": "Humanoid light",
      "author": "Thomas (tasmo) Friese",
      "base00": "#f8f8f2",
      "base01": "#efefe9",
      "base02": "#deded8",
      "base03": "#c0c0bd",
      "base04": "#60615d",
      "base05": "#232629",
      "base06": "#2f3337",
      "base07": "#070708",
      "base08": "#b0151a",
      "base09": "#ff3d00",
      "base0A": "#ffb627",
      "base0B": "#388e3c",
      "base0C": "#008e8e",
      "base0D": "#0082c9",
      "base0E": "#700f98",
      "base0F": "#b27701"
    },
    "icy": {
      "scheme": "Icy Dark",
      "author": "icyphox (https://icyphox.ga)",
      "base00": "#021012",
      "base01": "#031619",
      "base02": "#041f23",
      "base03": "#052e34",
      "base04": "#064048",
      "base05": "#095b67",
      "base06": "#0c7c8c",
      "base07": "#109cb0",
      "base08": "#16c1d9",
      "base09": "#b3ebf2",
      "base0A": "#80deea",
      "base0B": "#4dd0e1",
      "base0C": "#26c6da",
      "base0D": "#00bcd4",
      "base0E": "#00acc1",
      "base0F": "#0097a7"
    },
    "fruit-soda": {
      "scheme": "Fruit Soda",
      "author": "jozip",
      "base00": "#f1ecf1",
      "base01": "#e0dee0",
      "base02": "#d8d5d5",
      "base03": "#b5b4b6",
      "base04": "#979598",
      "base05": "#515151",
      "base06": "#474545",
      "base07": "#2d2c2c",
      "base08": "#fe3e31",
      "base09": "#fe6d08",
      "base0A": "#f7e203",
      "base0B": "#47f74c",
      "base0C": "#0f9cfd",
      "base0D": "#2931df",
      "base0E": "#611fce",
      "base0F": "#b16f40"
    },
    "darkviolet": {
      "scheme": "Dark Violet",
      "author": "ruler501 (https://github.com/ruler501/base16-darkviolet)",
      "base00": "#000000",
      "base01": "#231a40",
      "base02": "#432d59",
      "base03": "#593380",
      "base04": "#00ff00",
      "base05": "#b08ae6",
      "base06": "#9045e6",
      "base07": "#a366ff",
      "base08": "#a82ee6",
      "base09": "#bb66cc",
      "base0A": "#f29df2",
      "base0B": "#4595e6",
      "base0C": "#40dfff",
      "base0D": "#4136d9",
      "base0E": "#7e5ce6",
      "base0F": "#a886bf"
    },
    "eva-dim": {
      "scheme": "Eva Dim",
      "author": "kjakapat (https://github.com/kjakapat)",
      "base00": "#2a3b4d",
      "base01": "#3d566f",
      "base02": "#4b6988",
      "base03": "#55799c",
      "base04": "#7e90a3",
      "base05": "#9fa2a6",
      "base06": "#d6d7d9",
      "base07": "#ffffff",
      "base08": "#c4676c",
      "base09": "#ff9966",
      "base0A": "#cfd05d",
      "base0B": "#5de561",
      "base0C": "#4b8f77",
      "base0D": "#1ae1dc",
      "base0E": "#9c6cd3",
      "base0F": "#bb64a9"
    },
    "eva": {
      "scheme": "Eva",
      "author": "kjakapat (https://github.com/kjakapat)",
      "base00": "#2a3b4d",
      "base01": "#3d566f",
      "base02": "#4b6988",
      "base03": "#55799c",
      "base04": "#7e90a3",
      "base05": "#9fa2a6",
      "base06": "#d6d7d9",
      "base07": "#ffffff",
      "base08": "#c4676c",
      "base09": "#ff9966",
      "base0A": "#ffff66",
      "base0B": "#66ff66",
      "base0C": "#4b8f77",
      "base0D": "#15f4ee",
      "base0E": "#9c6cd3",
      "base0F": "#bb64a9"
    },
    "heetch": {
      "scheme": "Heetch Dark",
      "author": "Geoffrey Teale (tealeg@gmail.com)",
      "base00": "#190134",
      "base01": "#392551",
      "base02": "#5A496E",
      "base03": "#7B6D8B",
      "base04": "#9C92A8",
      "base05": "#BDB6C5",
      "base06": "#DEDAE2",
      "base07": "#FEFFFF",
      "base08": "#27D9D5",
      "base09": "#5BA2B6",
      "base0A": "#8F6C97",
      "base0B": "#C33678",
      "base0C": "#F80059",
      "base0D": "#BD0152",
      "base0E": "#82034C",
      "base0F": "#470546"
    },
    "heetch-light": {
      "scheme": "Heetch Light",
      "author": "Geoffrey Teale (tealeg@gmail.com)",
      "base00": "#feffff",
      "base01": "#392551",
      "base02": "#7b6d8b",
      "base03": "#9c92a8",
      "base04": "#ddd6e5",
      "base05": "#5a496e",
      "base06": "#470546",
      "base07": "#190134",
      "base08": "#27d9d5",
      "base09": "#bdb6c5",
      "base0A": "#5ba2b6",
      "base0B": "#f80059",
      "base0C": "#c33678",
      "base0D": "#47f9f5",
      "base0E": "#bd0152",
      "base0F": "#dedae2"
    },
    "nord": {
      "scheme": "Nord",
      "author": "arcticicestudio",
      "base00": "#2E3440",
      "base01": "#3B4252",
      "base02": "#434C5E",
      "base03": "#4C566A",
      "base04": "#D8DEE9",
      "base05": "#E5E9F0",
      "base06": "#ECEFF4",
      "base07": "#8FBCBB",
      "base08": "#88C0D0",
      "base09": "#81A1C1",
      "base0A": "#5E81AC",
      "base0B": "#BF616A",
      "base0C": "#D08770",
      "base0D": "#EBCB8B",
      "base0E": "#A3BE8C",
      "base0F": "#B48EAD"
    },
    "framer": {
      "scheme": "Framer",
      "author": "Framer (Maintained by Jesse Hoyos)",
      "base00": "#181818",
      "base01": "#151515",
      "base02": "#464646",
      "base03": "#747474",
      "base04": "#B9B9B9",
      "base05": "#D0D0D0",
      "base06": "#E8E8E8",
      "base07": "#EEEEEE",
      "base08": "#FD886B",
      "base09": "#FC4769",
      "base0A": "#FECB6E",
      "base0B": "#32CCDC",
      "base0C": "#ACDDFD",
      "base0D": "#20BCFC",
      "base0E": "#BA8CFC",
      "base0F": "#B15F4A"
    },
    "dracula": {
      "scheme": "Dracula",
      "author": "Mike Barkmin (http://github.com/mikebarkmin) based on Dracula Theme (http://github.com/dracula)",
      "base00": "#282936",
      "base01": "#3a3c4e",
      "base02": "#4d4f68",
      "base03": "#626483",
      "base04": "#62d6e8",
      "base05": "#e9e9f4",
      "base06": "#f1f2f8",
      "base07": "#f7f7fb",
      "base08": "#ea51b2",
      "base09": "#b45bcf",
      "base0A": "#00f769",
      "base0B": "#ebff87",
      "base0C": "#a1efe4",
      "base0D": "#62d6e8",
      "base0E": "#b45bcf",
      "base0F": "#00f769"
    },
    "atelier-cave-light": {
      "scheme": "Atelier Cave Light",
      "author": "Bram de Haan (http://atelierbramdehaan.nl)",
      "base00": "#efecf4",
      "base01": "#e2dfe7",
      "base02": "#8b8792",
      "base03": "#7e7887",
      "base04": "#655f6d",
      "base05": "#585260",
      "base06": "#26232a",
      "base07": "#19171c",
      "base08": "#be4678",
      "base09": "#aa573c",
      "base0A": "#a06e3b",
      "base0B": "#2a9292",
      "base0C": "#398bc6",
      "base0D": "#576ddb",
      "base0E": "#955ae7",
      "base0F": "#bf40bf"
    },
    "atelier-heath-light": {
      "scheme": "Atelier Heath Light",
      "author": "Bram de Haan (http://atelierbramdehaan.nl)",
      "base00": "#f7f3f7",
      "base01": "#d8cad8",
      "base02": "#ab9bab",
      "base03": "#9e8f9e",
      "base04": "#776977",
      "base05": "#695d69",
      "base06": "#292329",
      "base07": "#1b181b",
      "base08": "#ca402b",
      "base09": "#a65926",
      "base0A": "#bb8a35",
      "base0B": "#918b3b",
      "base0C": "#159393",
      "base0D": "#516aec",
      "base0E": "#7b59c0",
      "base0F": "#cc33cc"
    },
    "atelier-seaside-light": {
      "scheme": "Atelier Seaside Light",
      "author": "Bram de Haan (http://atelierbramdehaan.nl)",
      "base00": "#f4fbf4",
      "base01": "#cfe8cf",
      "base02": "#8ca68c",
      "base03": "#809980",
      "base04": "#687d68",
      "base05": "#5e6e5e",
      "base06": "#242924",
      "base07": "#131513",
      "base08": "#e6193c",
      "base09": "#87711d",
      "base0A": "#98981b",
      "base0B": "#29a329",
      "base0C": "#1999b3",
      "base0D": "#3d62f5",
      "base0E": "#ad2bee",
      "base0F": "#e619c3"
    },
    "atelier-plateau-light": {
      "scheme": "Atelier Plateau Light",
      "author": "Bram de Haan (http://atelierbramdehaan.nl)",
      "base00": "#f4ecec",
      "base01": "#e7dfdf",
      "base02": "#8a8585",
      "base03": "#7e7777",
      "base04": "#655d5d",
      "base05": "#585050",
      "base06": "#292424",
      "base07": "#1b1818",
      "base08": "#ca4949",
      "base09": "#b45a3c",
      "base0A": "#a06e3b",
      "base0B": "#4b8b8b",
      "base0C": "#5485b6",
      "base0D": "#7272ca",
      "base0E": "#8464c4",
      "base0F": "#bd5187"
    },
    "atelier-dune": {
      "scheme": "Atelier Dune",
      "author": "Bram de Haan (http://atelierbramdehaan.nl)",
      "base00": "#20201d",
      "base01": "#292824",
      "base02": "#6e6b5e",
      "base03": "#7d7a68",
      "base04": "#999580",
      "base05": "#a6a28c",
      "base06": "#e8e4cf",
      "base07": "#fefbec",
      "base08": "#d73737",
      "base09": "#b65611",
      "base0A": "#ae9513",
      "base0B": "#60ac39",
      "base0C": "#1fad83",
      "base0D": "#6684e1",
      "base0E": "#b854d4",
      "base0F": "#d43552"
    },
    "atelier-heath": {
      "scheme": "Atelier Heath",
      "author": "Bram de Haan (http://atelierbramdehaan.nl)",
      "base00": "#1b181b",
      "base01": "#292329",
      "base02": "#695d69",
      "base03": "#776977",
      "base04": "#9e8f9e",
      "base05": "#ab9bab",
      "base06": "#d8cad8",
      "base07": "#f7f3f7",
      "base08": "#ca402b",
      "base09": "#a65926",
      "base0A": "#bb8a35",
      "base0B": "#918b3b",
      "base0C": "#159393",
      "base0D": "#516aec",
      "base0E": "#7b59c0",
      "base0F": "#cc33cc"
    },
    "atelier-dune-light": {
      "scheme": "Atelier Dune Light",
      "author": "Bram de Haan (http://atelierbramdehaan.nl)",
      "base00": "#fefbec",
      "base01": "#e8e4cf",
      "base02": "#a6a28c",
      "base03": "#999580",
      "base04": "#7d7a68",
      "base05": "#6e6b5e",
      "base06": "#292824",
      "base07": "#20201d",
      "base08": "#d73737",
      "base09": "#b65611",
      "base0A": "#ae9513",
      "base0B": "#60ac39",
      "base0C": "#1fad83",
      "base0D": "#6684e1",
      "base0E": "#b854d4",
      "base0F": "#d43552"
    },
    "atelier-lakeside": {
      "scheme": "Atelier Lakeside",
      "author": "Bram de Haan (http://atelierbramdehaan.nl)",
      "base00": "#161b1d",
      "base01": "#1f292e",
      "base02": "#516d7b",
      "base03": "#5a7b8c",
      "base04": "#7195a8",
      "base05": "#7ea2b4",
      "base06": "#c1e4f6",
      "base07": "#ebf8ff",
      "base08": "#d22d72",
      "base09": "#935c25",
      "base0A": "#8a8a0f",
      "base0B": "#568c3b",
      "base0C": "#2d8f6f",
      "base0D": "#257fad",
      "base0E": "#6b6bb8",
      "base0F": "#b72dd2"
    },
    "atelier-sulphurpool": {
      "scheme": "Atelier Sulphurpool",
      "author": "Bram de Haan (http://atelierbramdehaan.nl)",
      "base00": "#202746",
      "base01": "#293256",
      "base02": "#5e6687",
      "base03": "#6b7394",
      "base04": "#898ea4",
      "base05": "#979db4",
      "base06": "#dfe2f1",
      "base07": "#f5f7ff",
      "base08": "#c94922",
      "base09": "#c76b29",
      "base0A": "#c08b30",
      "base0B": "#ac9739",
      "base0C": "#22a2c9",
      "base0D": "#3d8fd1",
      "base0E": "#6679cc",
      "base0F": "#9c637a"
    },
    "atelier-lakeside-light": {
      "scheme": "Atelier Lakeside Light",
      "author": "Bram de Haan (http://atelierbramdehaan.nl)",
      "base00": "#ebf8ff",
      "base01": "#c1e4f6",
      "base02": "#7ea2b4",
      "base03": "#7195a8",
      "base04": "#5a7b8c",
      "base05": "#516d7b",
      "base06": "#1f292e",
      "base07": "#161b1d",
      "base08": "#d22d72",
      "base09": "#935c25",
      "base0A": "#8a8a0f",
      "base0B": "#568c3b",
      "base0C": "#2d8f6f",
      "base0D": "#257fad",
      "base0E": "#6b6bb8",
      "base0F": "#b72dd2"
    },
    "atelier-savanna": {
      "scheme": "Atelier Savanna",
      "author": "Bram de Haan (http://atelierbramdehaan.nl)",
      "base00": "#171c19",
      "base01": "#232a25",
      "base02": "#526057",
      "base03": "#5f6d64",
      "base04": "#78877d",
      "base05": "#87928a",
      "base06": "#dfe7e2",
      "base07": "#ecf4ee",
      "base08": "#b16139",
      "base09": "#9f713c",
      "base0A": "#a07e3b",
      "base0B": "#489963",
      "base0C": "#1c9aa0",
      "base0D": "#478c90",
      "base0E": "#55859b",
      "base0F": "#867469"
    },
    "atelier-forest": {
      "scheme": "Atelier Forest",
      "author": "Bram de Haan (http://atelierbramdehaan.nl)",
      "base00": "#1b1918",
      "base01": "#2c2421",
      "base02": "#68615e",
      "base03": "#766e6b",
      "base04": "#9c9491",
      "base05": "#a8a19f",
      "base06": "#e6e2e0",
      "base07": "#f1efee",
      "base08": "#f22c40",
      "base09": "#df5320",
      "base0A": "#c38418",
      "base0B": "#7b9726",
      "base0C": "#3d97b8",
      "base0D": "#407ee7",
      "base0E": "#6666ea",
      "base0F": "#c33ff3"
    },
    "atelier-cave": {
      "scheme": "Atelier Cave",
      "author": "Bram de Haan (http://atelierbramdehaan.nl)",
      "base00": "#19171c",
      "base01": "#26232a",
      "base02": "#585260",
      "base03": "#655f6d",
      "base04": "#7e7887",
      "base05": "#8b8792",
      "base06": "#e2dfe7",
      "base07": "#efecf4",
      "base08": "#be4678",
      "base09": "#aa573c",
      "base0A": "#a06e3b",
      "base0B": "#2a9292",
      "base0C": "#398bc6",
      "base0D": "#576ddb",
      "base0E": "#955ae7",
      "base0F": "#bf40bf"
    },
    "atelier-seaside": {
      "scheme": "Atelier Seaside",
      "author": "Bram de Haan (http://atelierbramdehaan.nl)",
      "base00": "#131513",
      "base01": "#242924",
      "base02": "#5e6e5e",
      "base03": "#687d68",
      "base04": "#809980",
      "base05": "#8ca68c",
      "base06": "#cfe8cf",
      "base07": "#f4fbf4",
      "base08": "#e6193c",
      "base09": "#87711d",
      "base0A": "#98981b",
      "base0B": "#29a329",
      "base0C": "#1999b3",
      "base0D": "#3d62f5",
      "base0E": "#ad2bee",
      "base0F": "#e619c3"
    },
    "atelier-savanna-light": {
      "scheme": "Atelier Savanna Light",
      "author": "Bram de Haan (http://atelierbramdehaan.nl)",
      "base00": "#ecf4ee",
      "base01": "#dfe7e2",
      "base02": "#87928a",
      "base03": "#78877d",
      "base04": "#5f6d64",
      "base05": "#526057",
      "base06": "#232a25",
      "base07": "#171c19",
      "base08": "#b16139",
      "base09": "#9f713c",
      "base0A": "#a07e3b",
      "base0B": "#489963",
      "base0C": "#1c9aa0",
      "base0D": "#478c90",
      "base0E": "#55859b",
      "base0F": "#867469"
    },
    "atelier-plateau": {
      "scheme": "Atelier Plateau",
      "author": "Bram de Haan (http://atelierbramdehaan.nl)",
      "base00": "#1b1818",
      "base01": "#292424",
      "base02": "#585050",
      "base03": "#655d5d",
      "base04": "#7e7777",
      "base05": "#8a8585",
      "base06": "#e7dfdf",
      "base07": "#f4ecec",
      "base08": "#ca4949",
      "base09": "#b45a3c",
      "base0A": "#a06e3b",
      "base0B": "#4b8b8b",
      "base0C": "#5485b6",
      "base0D": "#7272ca",
      "base0E": "#8464c4",
      "base0F": "#bd5187"
    },
    "atelier-sulphurpool-light": {
      "scheme": "Atelier Sulphurpool Light",
      "author": "Bram de Haan (http://atelierbramdehaan.nl)",
      "base00": "#f5f7ff",
      "base01": "#dfe2f1",
      "base02": "#979db4",
      "base03": "#898ea4",
      "base04": "#6b7394",
      "base05": "#5e6687",
      "base06": "#293256",
      "base07": "#202746",
      "base08": "#c94922",
      "base09": "#c76b29",
      "base0A": "#c08b30",
      "base0B": "#ac9739",
      "base0C": "#22a2c9",
      "base0D": "#3d8fd1",
      "base0E": "#6679cc",
      "base0F": "#9c637a"
    },
    "atelier-forest-light": {
      "scheme": "Atelier Forest Light",
      "author": "Bram de Haan (http://atelierbramdehaan.nl)",
      "base00": "#f1efee",
      "base01": "#e6e2e0",
      "base02": "#a8a19f",
      "base03": "#9c9491",
      "base04": "#766e6b",
      "base05": "#68615e",
      "base06": "#2c2421",
      "base07": "#1b1918",
      "base08": "#f22c40",
      "base09": "#df5320",
      "base0A": "#c38418",
      "base0B": "#7b9726",
      "base0C": "#3d97b8",
      "base0D": "#407ee7",
      "base0E": "#6666ea",
      "base0F": "#c33ff3"
    },
    "atelier-estuary": {
      "scheme": "Atelier Estuary",
      "author": "Bram de Haan (http://atelierbramdehaan.nl)",
      "base00": "#22221b",
      "base01": "#302f27",
      "base02": "#5f5e4e",
      "base03": "#6c6b5a",
      "base04": "#878573",
      "base05": "#929181",
      "base06": "#e7e6df",
      "base07": "#f4f3ec",
      "base08": "#ba6236",
      "base09": "#ae7313",
      "base0A": "#a5980d",
      "base0B": "#7d9726",
      "base0C": "#5b9d48",
      "base0D": "#36a166",
      "base0E": "#5f9182",
      "base0F": "#9d6c7c"
    },
    "atelier-estuary-light": {
      "scheme": "Atelier Estuary Light",
      "author": "Bram de Haan (http://atelierbramdehaan.nl)",
      "base00": "#f4f3ec",
      "base01": "#e7e6df",
      "base02": "#929181",
      "base03": "#878573",
      "base04": "#6c6b5a",
      "base05": "#5f5e4e",
      "base06": "#302f27",
      "base07": "#22221b",
      "base08": "#ba6236",
      "base09": "#ae7313",
      "base0A": "#a5980d",
      "base0B": "#7d9726",
      "base0C": "#5b9d48",
      "base0D": "#36a166",
      "base0E": "#5f9182",
      "base0F": "#9d6c7c"
    },
    "ia-light": {
      "scheme": "iA Light",
      "author": "iA Inc. (modified by aramisgithub)",
      "base00": "#f6f6f6",
      "base01": "#dedede",
      "base02": "#bde5f2",
      "base03": "#898989",
      "base04": "#767676",
      "base05": "#181818",
      "base06": "#e8e8e8",
      "base07": "#f8f8f8",
      "base08": "#9c5a02",
      "base09": "#c43e18",
      "base0A": "#c48218",
      "base0B": "#38781c",
      "base0C": "#2d6bb1",
      "base0D": "#48bac2",
      "base0E": "#a94598",
      "base0F": "#8b6c37"
    },
    "ia-dark": {
      "scheme": "iA Dark",
      "author": "iA Inc. (modified by aramisgithub)",
      "base00": "#1a1a1a",
      "base01": "#222222",
      "base02": "#1d414d",
      "base03": "#767676",
      "base04": "#b8b8b8",
      "base05": "#cccccc",
      "base06": "#e8e8e8",
      "base07": "#f8f8f8",
      "base08": "#d88568",
      "base09": "#d86868",
      "base0A": "#b99353",
      "base0B": "#83a471",
      "base0C": "#7c9cae",
      "base0D": "#8eccdd",
      "base0E": "#b98eb2",
      "base0F": "#8b6c37"
    },
    "brushtrees": {
      "scheme": "Brush Trees",
      "author": "Abraham White <abelincoln.white@gmail.com>",
      "base00": "#E3EFEF",
      "base01": "#C9DBDC",
      "base02": "#B0C5C8",
      "base03": "#98AFB5",
      "base04": "#8299A1",
      "base05": "#6D828E",
      "base06": "#5A6D7A",
      "base07": "#485867",
      "base08": "#b38686",
      "base09": "#d8bba2",
      "base0A": "#aab386",
      "base0B": "#87b386",
      "base0C": "#86b3b3",
      "base0D": "#868cb3",
      "base0E": "#b386b2",
      "base0F": "#b39f9f"
    },
    "brushtrees-dark": {
      "scheme": "Brush Trees Dark",
      "author": "Abraham White <abelincoln.white@gmail.com>",
      "base00": "#485867",
      "base01": "#5A6D7A",
      "base02": "#6D828E",
      "base03": "#8299A1",
      "base04": "#98AFB5",
      "base05": "#B0C5C8",
      "base06": "#C9DBDC",
      "base07": "#E3EFEF",
      "base08": "#b38686",
      "base09": "#d8bba2",
      "base0A": "#aab386",
      "base0B": "#87b386",
      "base0C": "#86b3b3",
      "base0D": "#868cb3",
      "base0E": "#b386b2",
      "base0F": "#b39f9f"
    },
    "xcode-dusk": {
      "scheme": "XCode Dusk",
      "author": "Elsa Gonsiorowski (https://github.com/gonsie)",
      "base00": "#282B35",
      "base01": "#3D4048",
      "base02": "#53555D",
      "base03": "#686A71",
      "base04": "#7E8086",
      "base05": "#939599",
      "base06": "#A9AAAE",
      "base07": "#BEBFC2",
      "base08": "#B21889",
      "base09": "#786DC5",
      "base0A": "#438288",
      "base0B": "#DF0002",
      "base0C": "#00A0BE",
      "base0D": "#790EAD",
      "base0E": "#B21889",
      "base0F": "#C77C48"
    },
    "codeschool": {
      "scheme": "Codeschool",
      "author": "blockloop",
      "base00": "#232c31",
      "base01": "#1c3657",
      "base02": "#2a343a",
      "base03": "#3f4944",
      "base04": "#84898c",
      "base05": "#9ea7a6",
      "base06": "#a7cfa3",
      "base07": "#b5d8f6",
      "base08": "#2a5491",
      "base09": "#43820d",
      "base0A": "#a03b1e",
      "base0B": "#237986",
      "base0C": "#b02f30",
      "base0D": "#484d79",
      "base0E": "#c59820",
      "base0F": "#c98344"
    },
    "sandcastle": {
      "scheme": "Sandcastle",
      "author": "George Essig (https://github.com/gessig)",
      "base00": "#282c34",
      "base01": "#2c323b",
      "base02": "#3e4451",
      "base03": "#665c54",
      "base04": "#928374",
      "base05": "#a89984",
      "base06": "#d5c4a1",
      "base07": "#fdf4c1",
      "base08": "#83a598",
      "base09": "#a07e3b",
      "base0A": "#a07e3b",
      "base0B": "#528b8b",
      "base0C": "#83a598",
      "base0D": "#83a598",
      "base0E": "#d75f5f",
      "base0F": "#a87322"
    },
    "snazzy": {
      "scheme": "Snazzy",
      "author": "Chawye Hsu (https://github.com/h404bi) based on Hyper Snazzy Theme (https://github.com/sindresorhus/hyper-snazzy)",
      "base00": "#282a36",
      "base01": "#34353e",
      "base02": "#43454f",
      "base03": "#78787e",
      "base04": "#a5a5a9",
      "base05": "#e2e4e5",
      "base06": "#eff0eb",
      "base07": "#f1f1f0",
      "base08": "#ff5c57",
      "base09": "#ff9f43",
      "base0A": "#f3f99d",
      "base0B": "#5af78e",
      "base0C": "#9aedfe",
      "base0D": "#57c7ff",
      "base0E": "#ff6ac1",
      "base0F": "#b2643c"
    },
    "nova": {
      "scheme": "Nova",
      "author": "George Essig (https://github.com/gessig), Trevor D. Miller (https://trevordmiller.com)",
      "base00": "#3C4C55",
      "base01": "#556873",
      "base02": "#6A7D89",
      "base03": "#899BA6",
      "base04": "#899BA6",
      "base05": "#C5D4DD",
      "base06": "#899BA6",
      "base07": "#556873",
      "base08": "#83AFE5",
      "base09": "#7FC1CA",
      "base0A": "#A8CE93",
      "base0B": "#7FC1CA",
      "base0C": "#F2C38F",
      "base0D": "#83AFE5",
      "base0E": "#9A93E1",
      "base0F": "#F2C38F"
    },
    "material-vivid": {
      "scheme": "Material Vivid",
      "author": "joshyrobot",
      "base00": "#202124",
      "base01": "#27292c",
      "base02": "#323639",
      "base03": "#44464d",
      "base04": "#676c71",
      "base05": "#80868b",
      "base06": "#9e9e9e",
      "base07": "#ffffff",
      "base08": "#f44336",
      "base09": "#ff9800",
      "base0A": "#ffeb3b",
      "base0B": "#00e676",
      "base0C": "#00bcd4",
      "base0D": "#2196f3",
      "base0E": "#673ab7",
      "base0F": "#8d6e63"
    }
  }

export default themes;